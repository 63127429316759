import { defineStore } from 'pinia';
import { useHaptics } from '@/services/HapticsAdapter';

interface IMessageStore {
    showMessage: boolean;
    text: string;
    color: string;
    duration: number;
    position: string;
}

export const useMessageStore = defineStore('messages', {
    state: () => ({
        showMessage: false,
        text: '',
        color: 'primary',
        duration: 2500,
        position: 'middle'
    } as IMessageStore),

    actions: {
        setSuccess(text: string, duration = 2500) {
            useHaptics.vibrate();
            this.text = text;
            this.color = 'primary';
            this.showMessage = true;
            this.hideMessageAfter(duration);
        },

        setError(text: string, duration = 2500) {
            useHaptics.impactHard();
            this.text = text;
            this.color = 'danger';
            this.showMessage = true;
            this.hideMessageAfter(duration);
        },

        hideMessageAfter(duration: number) {
            setTimeout(() => {
                this.showMessage = false;
                this.text = '';
            }, duration)
        }
    }
});
