import { apiAdapter } from "./ApiAdapter";
import { endpointDepositAppService } from "./Constants";

interface ITo {
    id: string;
    name: string;
}

export async function registerRvmDeposit(code: string) {
    return await apiAdapter.post(`${endpointDepositAppService}/scan/rvm`, { code: code });
}


export async function transferMoney(fromName: string, to: ITo, amount: number, balanceId = 'oestecim') {
    return await apiAdapter.post(`${endpointDepositAppService}/transfer`, { fromName, to, amount, balanceId });
}

interface RegisterManualRvmReceiptPayload {
    machineId: string;
    depositId: string;
    amount: number;
    ticketNumber?: string;
    depositedBy: string;
    handledBy: string;
    facilityId: string;
    facilityName: string;
}

export async function registerManualRvmReceipt(payload: RegisterManualRvmReceiptPayload) {
    return await apiAdapter.post(`${endpointDepositAppService}/manual/rvm`, payload);
}