import { IBalance, IFromTo, IHistoryEntry, ITxType } from "@/types";


export class HistoryEntry implements IHistoryEntry {
    id: string;
    type: ITxType;
    date: string;
    payment: number;
    balance: IBalance;
    from: IFromTo;
    to: IFromTo;
    ticketNumber?: string;
    handledBy?: string;

    constructor(e: IHistoryEntry) {
        this.id = e.id;
        this.type = e.type;
        this.date = e.date;
        this.payment = e.payment;
        this.balance = this.setBalance(e.balance);
        this.from = this.setFromTo(e.from);
        this.to = this.setFromTo(e.to);
        this.ticketNumber = e.ticketNumber;
        this.handledBy = e.handledBy;
    }

    setFromTo(entity: IFromTo) {
        return { name: entity.name, id: entity.id };
    }

    setBalance(b: IBalance) {
        return { 
            id: b.id,
            type: b.type,
            name: b.name,
            prefix: b.prefix,
        } 
    }
}