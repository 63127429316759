
const updateEventHandler = () => {
  const route = window.location.pathname;
  // If user is currently trying to transfer or scan a qr code, then wait 10 sec and try again to reload.
  if (route.includes('transfer') || route.includes('scan')) {
      console.log('User is currently trying to transfer or scan a qr code, waiting 10 sec and trying again to reload.');
      setTimeout(() => {
          updateEventHandler();
      }, 10000);
  } else {
      console.log('User not busy, reloading site.');
      reloadSite();
  }
}

export function registerUpdateHandler() {
  window.addEventListener("sw-version-change", updateEventHandler);
}

export const checkForNewAppRelease = () => {
  if (navigator && 'serviceWorker' in navigator && window.isSecureContext && !navigator.userAgent.includes('Cypress')) {
    navigator.serviceWorker.getRegistration().then(worker => {
      if (worker) {
        worker.update().then(() => {
          console.log('Checking for updates');
       });
     }
   })
 }
}

export function reloadSite() {
  // if page was refreshed less than 60 seconds ago, then skip refreshing.
  const lastUpdated = localStorage.getItem('reloads');
  if (!lastUpdated) {
  localStorage.setItem('reloads', JSON.stringify({updatedAt: Date.now(), updatedCount: 1}));
  location.reload();
  } else {
  const { updatedAt, updatedCount } = JSON.parse(lastUpdated);
  const currentTime = Date.now();
  if (currentTime > (updatedAt + (60 * 1 * 1000))) {
      localStorage.setItem('reloads', JSON.stringify({updatedAt: currentTime, updatedCount: updatedCount + 1}));
      location.reload();
  }
  }
}