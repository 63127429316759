import { defineStore } from "pinia";
import { storageAdapter } from '@/services/StorageAdapter'
import { getDeviceLocale } from '@/services/DeviceAdapter';


export const useSettingsStore = defineStore('settings', {
    state: () => ({
        currentTab: 'Settings',
        language: 'en',
        translations: ['en', 'pt', 'nb'],
        defaultLanguage: 'en'
    }),

    actions: {
        setTab(tab: string) {
            this.currentTab = tab;
        },

        async getUserLanguage() {
            const userPreference = await storageAdapter.get('language');
            if (userPreference) {
                this.language = userPreference;
                return;
            }
            const localeCode = await getDeviceLocale();
            if (localeCode in this.translations) {
                this.language = localeCode;
            } else {
                this.language = this.defaultLanguage;
            }
        },

        async changeUserLanguage(lang: string) {
            if (this.translations.includes(lang)) {
                this.language = lang;
                await storageAdapter.set('language', lang);
            }
        },

    }
})