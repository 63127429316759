export const AuthenticationRoutes = {
    path: '/',
    meta: {
      requireAuth: false,
    },
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        redirect: '/login',
        meta: {
            requireAuth: false,
        },
      },
      {
        path: '/login',
        component: () => import('@/views/auth/LoginPage.vue'),
        meta: {
            requireAuth: false,
        },
      },
      {
        path: '/language',
        component: () => import('@/views/LanguagePage.vue'),
        meta: {
            requireAuth: false,
        },
      }
    ]
}