import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { AuthenticationRoutes } from './AuthenticationRoutes';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { useUserStore } from '@/store/UserStore';

const routes: Array<RouteRecordRaw> = [
  AuthenticationRoutes,
  AuthenticatedRoutes,
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/OnboardingPage.vue'),
    meta: {
        requireAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // if user is logged in then push user to /app
  const userStore = useUserStore();
  if (to.meta.requireAuth && !userStore.isLoggedIn) {
    return next('/login');
  }
  if (to.meta.requireAuth === false && userStore.isLoggedIn) {
    return next('/app');
  }
  // if user is not logged in and accessing route with meta -> requiresAuth: true
  // push user to /login
  next();
})

export default router
