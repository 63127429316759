import { Device } from '@capacitor/device';

export const getDeviceLocale = async () => {
    return (await Device.getLanguageCode()).value
}

export const logDeviceInfo = async () => {
  const info = await Device.getInfo();

  console.log(info);
};

export const logBatteryInfo = async () => {
  const info = await Device.getBatteryInfo();

  console.log(info);
};