import { IUser, IUserRole } from "@/types";


export class User implements IUser {
    constructor(
        public email: string,
        public firstName: string,
        public lastName: string,
        public userId: string,
        public roles: IUserRole[],
        public token: string,
        ) {
            this.setRoles(roles);
        }

        setRoles(roles: IUserRole[]) {
            this.roles = roles.map(r => { 
                return { entityId: r.entityId, role: r.role }
            });
        }
}