/**
 * Storage gives access to a local structured sql database
 * This is the unencrypted version of the storage solution.
 * Might be 
 */

import { Storage } from '@ionic/storage';


export class StorageAdapter {
    private _storage: Storage | null = null;

    async init(storage: Storage) {
        const connected = await storage.create();
        this._storage = connected;
    }

    public async set(key: string, value: any) {
        await this._storage?.set(key, value);
    }

    public async get(key: string) {
        return await this._storage?.get(key);
    }

    public async remove(key: string) {
        await this._storage?.remove(key);
    }

    public async clear() {
        await this._storage?.clear();
    }
}

export const storageAdapter = new StorageAdapter();