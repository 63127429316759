/**
 * Haptics work on devices that have a taptic engine or vibrator. For other devices, nothing happens when called.
 * Excellent to provides feedback on button clicks, QR code detected and scanned,
 */
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export const useHaptics = {
  impactHard: async () => {
    await Haptics.impact({ style: ImpactStyle.Heavy });
  },
  impactMedium: async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
  },
  impactLight: async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
  },
  vibrate: async () => {
    await Haptics.vibrate();
  },
  selectionStart: async () => {
    await Haptics.selectionStart();
  },
  selectionChanged: async () => {
    await Haptics.selectionChanged();
  },
  selectionEnd: async () => {
    await Haptics.selectionEnd();
  }
}