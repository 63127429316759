import { CapacitorHttp, HttpResponse } from '@capacitor/core';

class ApiAdapter {
    private _token: string | undefined = undefined;

    setToken(token: string | undefined) {
        this._token = token;
    }

    async get(url: string, params?: {[key: string]: string}) {
        const options = {
            url,
            headers: {
                'Authorization': `${this._token}`, 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            params
        }

        const response: HttpResponse = await CapacitorHttp.get(options);
        return this.handleHttpResponse(response)
    }
    
    async post(url: string, payload: any) {
        const options = {
            url,
            headers: {
                'Authorization': `${this._token}`, 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: payload 
        };
        const response: HttpResponse = await CapacitorHttp.post(options);
        return this.handleHttpResponse(response);
    }

    async put(url: string, payload: any) {
        const options = {
            url,
            headers: {
                'Authorization': `${this._token}`, 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: payload   
        };
        const response: HttpResponse = await CapacitorHttp.put(options);
        return this.handleHttpResponse(response)
    }

    async handleHttpResponse(response: HttpResponse) {
        if (this.success(response.status)) {
            // User service is not returning json but serialized json..
            if (typeof response.data === 'string' && response.data.length > 0) {
                return JSON.parse(response.data);
            } else {
                return response.data;
            }
        } else {
            return this.handleError(response);
        }
    }

    success(code: number): boolean {
        const start = code.toString().charAt(0);
        if (start === '2' || start === '3') {
            return true;
        } else {
            return false;
        }
    }

    handleError(response: HttpResponse) {
        // Do something better with the error handler here.
        // Maybe handle translations of errors here
        if (response.data) {
            // need to handle fucking json parsing because the endpoint are returning text/html content type 
            let message = '';
            if (typeof response.data === 'string' && response.data.length > 0) {
                const json = JSON.parse(response.data);
                message = json.message;
            } else if (response.data.message) {
                message = response.data.message;
            }
            throw new Error(message);
        } else {
            throw new Error('Something went wrong');
        }
    }

    // Don't think we need this for anything yet. 
    // delete(url: string, payload: any) {
    //     // nothing
    // }
}


export const apiAdapter = new ApiAdapter();
