import mitt, {Emitter} from 'mitt'


type Events = {
    qrCodeDetected: string;
    startScanner: void;
    stopScanner: void;
    pauseScanner: void;
}
export const adapterbus: Emitter<Events> = mitt<Events>();

