import { storageAdapter } from '@/services/StorageAdapter'
import { getDeviceLocale } from '@/services/DeviceAdapter';

const translations = {
    "en": {
     
        "login": "Login",
        "logout": 'Logout',
        "signup": "Sign Up",
        "first_name": 'First Name',
        "last_name": 'Last Name',
        "email": 'Email',
        "password": 'Password',
        "concent": 'I agree to the',
        "privacy_policy": 'Privacy Policy',
        "terms": 'Terms of Service',


        "navbar_settings": 'Settings',
        "navbar_login": 'Login',
        "navbar_register": 'Register',
        "navbar_scan": 'Scan',
        "navbar_history": "History",
        "settings": "Settings",
        "language": "Language",

        "select_language": "Select Language",
        "english": "English",
        "portuguese": "Portuguese",
        "french": "French",
        "norwegian": "Norwegian",

        "next": "Next",
        "back": "Back",


        "date": "Date",
        "customer_name": "Customer name",
        "amount": "Amount",

        "facility_qr": "The QR code for this facility",
        "login_wrong_error": "Wrong email or password",
        "default_input_error": "Something went wrong, trying filling in the info again",

        "rvm_scan_success": "Deposit registered",
        "rvm_scan_error_duplicate": "The QR code has already been scanned, try refreshing the history.",
        "qr_scan_error": "Something went wrong with scanning the QR code, please try again",
        "history_export": "Export History",
        "filter_by_date": "Select date to view",
        "cancel": "Cancel",
        "filter_select": "Select",
        "filter_clear": "Clear",

        "select_handler": "Enter your name",
        "your_name": "Your name",
        "confirm": "Confirm",

        "update_record": "Update Record",
        "save": "Save",
        "ticket_number": "Ticket Number",
        "handled_by": "Handled By",
        "change_user": "Change User",
        "name_too_short": "Name must be at least 3 characters long",
        
        "manual_rvm_input": "RVM Registration",
        "machine_id": "Machine ID",
        "deposit_id": "Deposit ID",
        "machine_id_required": "Machine ID is required",
        "deposit_id_required": "Deposit ID is required",
        "amount_required": "Amount is required",
        "customer_name_required": "Customer name is required",
    },
    "nb": {
        "login": "Logg inn",
        "logout": 'Logg ut',
        "signup": "Registrer deg",
        "first_name": 'Fornavn',
        "last_name": 'Etternavn',
        "email": 'E-post',
        "password": 'Passord',
        "concent": 'Jeg godtar',
        "privacy_policy": 'Personvern',
        "terms": 'Vilkår',


        "navbar_settings": 'Innstillinger',
        "navbar_login": 'Logg inn',
        "navbar_register": 'Registrer deg',
        "navbar_scan": 'Skann',
        "navbar_history": "Historikk",
        "settings": "Innstillinger",
        "language": "Språk",

        "select_language": "Velg språk",
        "english": "Engelsk",
        "portuguese": "Portugisisk",
        "french": "Fransk",
        "norwegian": "Norsk",

        "next": "Neste",
        "back": "Tilbake",

        
        "date": "Dato",
        "customer_name": "Kundenavn",
        "amount": "Beløp",

        "facility_qr": "QR-koden for denne fasiliteten",
        "login_wrong_error": "Feil epost eller passord",
        "default_input_error": "Noe gikk galt, prøv å fylle inn infoen på nytt",
        "rvm_scan_success": "Innlevering registrert.",
        "rvm_scan_error_duplicate": "QR koden har allerede blitt skannet.",
        "qr_scan_error": "No gikk galt med skanningen av QR koden, prøv igjen",
        "history_export": "Eksporter historikk",
        "filter_by_date": "Velg dato for å se historikk",
        "cancel": "Avbryt",
        "filter_select": "Velg",
        "filter_clear": "Tøm",

        "select_handler": "Skriv inn navnet ditt",
        "your_name": "Ditt navn",
        "confirm": "Bekreft",

        "update_record": "Oppdater oppføring",
        "save": "Lagre",
        "ticket_number": "Billett Nummer",
        "handled_by": "Behandlet av",
        "change_user": "Bytt bruker",
        "name_too_short": "Navnet må være minst 3 tegn langt",

        "manual_rvm_input": "RVM Registrering",
        "machine_id": "Maskin ID",
        "deposit_id": "Innlevering ID",
        "machine_id_required": "Maskin ID er påkrevd",
        "deposit_id_required": "Innlevering ID er påkrevd",
        "amount_required": "Beløp er påkrevd",
        "customer_name_required": "Kundenavn er påkrevd",
    },
    "pt": {
        "login": "Iniciar Sessão",
        "logout": 'Terminar Sessão',
        "signup": "Registar",
        "first_name": 'Primeiro Nome',
        "last_name": 'Último Nome',
        "email": 'Email',
        "password": 'Palavra-passe',
        "concent": 'Eu concordo com a',
        "privacy_policy": 'Política de Privacidade',
        "terms": 'Termos de Serviço',


        "navbar_settings": 'Definições',
        "navbar_login": 'Iniciar Sessão',
        "navbar_scan": 'Ler',
        "navbar_register": 'Registar', 
        "navbar_history": "Histórico",
        "settings": "Definições",
        "language": "Idioma", 
        "select_language": "Selecione Idioma", 
        "english": "Inglês", 
        "portuguese": "Português",
        "french": "Francês",
        "norwegian": "Norueguês",

        "next": "Próximo",
        "back": "Voltar",
  
        
        "date": "Data",
        "customer_name": "Nome do Cliente",
        "amount": "Montante",

        "facility_qr": "O código QR para esta instalação",
        "login_wrong_error": "Email ou password errado",
        "default_input_error": "Um erro ocorreu, tente preencher a informação de novo",
        "rvm_scan_success": "Depósito registado.", 
        "rvm_scan_error_duplicate": "Este QR code já foi registado, tente refrescar a histórico.", 
        "qr_scan_error": "Um erro ocorreu. Por favor tente novamente!",
        "history_export": "Exportar histórico",
        "filter_by_date": "Selecione a data para ver",
        "cancel": "Cancelar",
        "filter_select": "Selecionar",
        "filter_clear": "Limpar",

        "select_handler": "Insira o seu nome",
        "your_name": "O seu nome",
        "confirm": "Confirmar",
        "save": "Guardar",
        "update_record": "Atualizar registo",
        "ticket_number": "Número do Bilhete",
        "handled_by": "Manipulado por",
        "change_user": "Mudar utilizador",
        "name_too_short": "O nome deve ter pelo menos 3 caracteres",

        "manual_rvm_input": "RVM Registo",
        "machine_id": "ID da Máquina",
        "deposit_id": "ID do Depósito",
        "machine_id_required": "ID da Máquina é obrigatório",
        "deposit_id_required": "ID do Depósito é obrigatório",
        "amount_required": "Montante é obrigatório",
        "customer_name_required": "Nome do Cliente é obrigatório",
    },
}


export const getUserLanguage = async () => {
    const userPreference = await storageAdapter.get('language');
    if (userPreference) {
        currentLocale = userPreference;
        return;
    }
    const localeCode = await getDeviceLocale();
    if (localeCode in translations) {
        currentLocale = localeCode;
        return;
    } else {
        currentLocale = defaultLocale;
        return;
    }
}

/* eslint-disable */
let currentLocale = 'en';
const defaultLocale = 'en';

export const changeUserLanguage = async (lang: string) => {
    currentLocale = lang;
    await storageAdapter.set('language', lang);
}

export const languagePlugin = (key: string) => {
    try {
        // @ts-ignore
        const t = translations[currentLocale][key];
        if (!t) {
           // @ts-ignore
           return translations[defaultLocale][key];
        } else {
           return t;
        }
    } catch (e) {
        console.log(e)
        // @ts-ignore
        return translations[defaultLocale][key];
    }
     
}