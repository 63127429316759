import { register } from 'register-service-worker';

function logger(message: string) {
  console.log(message);
}

const updateEvent = new CustomEvent("sw-version-change");

let updateInterval: NodeJS.Timer;

const setWorkerOnUpdateFunc = (worker: ServiceWorkerRegistration) => {
  worker.onupdatefound = () => {
    logger('Found update. Reloading site.');
    if (updateInterval) {
      clearInterval(updateInterval);
    }
    window.dispatchEvent(updateEvent);
  }
}

const setWorkerUpdateIntervalFunc = (worker: ServiceWorkerRegistration) => {
  const refreshTime = 60 * 1 * 1000; // check for new version every minute
  updateInterval = setInterval(() => {
    worker.update().then(() => {
      logger('Checking for updates');
    });
  }, refreshTime);
}

export const registerServiceWorker = () => {
  if (navigator.userAgent.includes('Cypress')) {
    logger('Cypress agent, service worker not registered.');
    return;
  }

  // if (process.env.NODE_ENV === "development") {
  //   logger('Running in development, service worker not registered');
  //   return;
  // }
  
  if ('serviceWorker' in navigator && window.isSecureContext) {
    register(`${process.env.BASE_URL}sw.js`, {
      ready() {
        logger('Service worker ready');
        navigator.serviceWorker.getRegistration().then(worker => {
          if (worker) {
              setWorkerOnUpdateFunc(worker);
              setWorkerUpdateIntervalFunc(worker);
          }
        });
      },
      registered() {
        logger('Service worker has been registered.');
      },
      cached() {
        logger('Content has been cached for offline use.');
      },
      updatefound() {
        logger('New content is downloading.')
      },
      updated() {
        logger('New content downloaded.');
      },
      offline() {
        logger('No internet connection found. App is running in offline mode.');
      },
      error(error) {
        logger('Error during service worker registration');
        throw error;
      }
    });
  }
}

    

