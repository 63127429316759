
export const AuthenticatedRoutes = {
    path: '/app/',
    component: () => import('@/layouts/AuthenticatedLayout.vue'),
    meta: {
        requireAuth: true,
    },
    children: [
      {
        path: '',
        redirect: '/app/history',
      },
      {
        path: 'history',
        name: 'history',
        component: () => import('@/views/HistoryPage.vue'),
        meta: {
            requireAuth: true,
        },
      },
      {
        path: 'scan',
        name: 'deposit',
        component: () => import('@/views/ScanPage.vue'),
        meta: {
            requireAuth: true,
        }
      },
      {
        path: 'qrcode',
        name: 'qrcode',
        component: () => import('@/views/QrCodePage.vue'),
        meta: {
            requireAuth: true,
        }
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/settings/SettingsPage.vue'),
        meta: {
            requireAuth: true,
        }
      },
    ],
}