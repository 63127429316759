import { defineStore } from 'pinia';
import { adapterbus } from '@/services/EventBus';

interface IAdapterStore {
    scannerIsActive: boolean;
}

export const useAdapterStore = defineStore('adapter', {
    state: () => ({
        scannerIsActive: false,
    } as IAdapterStore),

    actions: {
        setScannerIsActive(value: boolean) {
            this.scannerIsActive = value;
            if (value === true) {
                adapterbus.emit('startScanner');
            } else {
                adapterbus.emit('stopScanner');
            }
        },
    }
});
