import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue, isPlatform } from '@ionic/vue';

/* Mounting Pinia as datastore */
import { createPinia } from 'pinia';
const pinia = createPinia();

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


const app = createApp(App)
  .use(IonicVue)
  .use(pinia);

import { useUserStore, useSettingsStore } from './store';
import { Storage } from '@ionic/storage';
import { storageAdapter } from './services/StorageAdapter';
import { languagePlugin, getUserLanguage } from './services/Language';
import LiveUpdateService from './services/LiveUpdates';
import { registerServiceWorker, registerUpdateHandler } from './serviceWorker';

export let liveUpdateService: LiveUpdateService;

const bootstrapApp = async () => {
  // First we need to make sure the client side storage is initialized 
  const storage = new Storage();
  await storageAdapter.init(storage);

  // Register service worker and update handler for PWA mode
  if (isPlatform("mobileweb") || isPlatform("desktop") || isPlatform("hybrid") || isPlatform("pwa")) {
    registerServiceWorker();
    registerUpdateHandler();
  }

  // Set user language
  const settings = useSettingsStore();
  await settings.getUserLanguage();
  await getUserLanguage();
  app.config.globalProperties.$t = languagePlugin;

  // Then we need to check if the user has a valid token
  const userStore = useUserStore();
  await userStore.checkUserAuth();
  // Should be showing some loading icon while this bootstrap process is happening

  // For realtime updates of the history

  // Finally we mount the router, wait for it to be ready and then mount the app
  app.use(router);
  router.isReady().then(() => {
    app.mount('#app');
    // Initialize the live update service after the app has been mounted so the pinia store is available
    liveUpdateService = new LiveUpdateService();
  });
}

bootstrapApp();
