import { Realtime, Types } from "ably";
import { endpointFacilityService } from '@/api/Constants';
import { apiAdapter } from "@/api/ApiAdapter";
import { useHistoryStore, useUserStore } from "@/store";

export default class LiveUpdateService {
    notificationEndpoint = process.env.VUE_APP_FACILITY_API_BASE_URL + '/api/v1/facility';
    ablyClient: Realtime | null = null;
    channels = [];
    store = useHistoryStore();
    facilityId: string;
    userToken: string;


    constructor() {
        const userStore = useUserStore();
        this.facilityId = userStore.facilityId || '';
        this.userToken = userStore.token || '';
        if (this.facilityId && this.userToken) {
            this.connectAblyClient();
        }

    }

    setUserInfo(userToken: string, facilityId: string | null) {
        this.facilityId = facilityId ? facilityId : '';
        this.userToken = userToken;
    }

    async connectAblyClient() {
        await this.getAblyClient();
    }

    async closeAblyClient() {
        if (this.ablyClient) {
            await this.ablyClient.connection.close();
            this.ablyClient = null;
        }
    }

    isConnected() {
        return !!this.ablyClient;
    }

    async getAblyClient() {
        if (!this.ablyClient || !this.ablyClient.connection) {
            const tokenDetails = await apiAdapter.get(`${endpointFacilityService}/${this.facilityId}/token-request`);
            this.ablyClient = new Realtime({
                tokenDetails: tokenDetails,
                closeOnUnload: true,
                authUrl: `${this.notificationEndpoint}/facilities/${this.facilityId}/token-request`,
                authHeaders: {'Authorization': `Bearer ${this.userToken}`, 'Accept': 'application/json', 'Content-Type': 'application/json'},
                authMethod: 'GET',
            });
            this.ablyClient.connection.on('connected', async () => {
                if (this.ablyClient) {
                    const facilityChannel = this.ablyClient.channels.get(this.facilityId);
                    this.subscribeToFacilityChannel(facilityChannel);
                }
            });
        }
    }

    subscribeToFacilityChannel(channel: Types.RealtimeChannelCallbacks) {
        channel.subscribe(message => {
            if (message.name === 'deposit.transfer') {
                const payload = JSON.parse(message.data)
                this.store.patchHistory(payload);
            }
        })
    }

}
