import { defineStore } from 'pinia';
import { IUserStore, RegisterPayload, IFacility } from '@/types';
import { storageAdapter } from '@/services/StorageAdapter';
import { apiAdapter } from '@/api/ApiAdapter';
import { User } from '@/entities/User';
import { endpointUserService, endpointFacilityService } from '@/api/Constants';
import QRCode from 'qrcode';
import { liveUpdateService } from '@/main';

export const useUserStore = defineStore('user', {
    state: () => ({
        isLoggedIn: false,
        user: null,
        id: null,
        qrCode: null,
        facilityId: null,
        facilityName: null,
        organizationId: null,
        token: null,
        handlerName: null,
    } as IUserStore),

    actions: {
        async login(email: string, password: string) {
            const { token, userId } = await apiAdapter.post(`${endpointUserService}/login`, {email, password});
            if (token && userId) {
                await this.saveInStorage(token, userId);
                await this.setUser();
                liveUpdateService.setUserInfo(token, this.facilityId);
                liveUpdateService.connectAblyClient();
            }
        },

        async saveInStorage(token: string, userId: string) {
            await storageAdapter.set('token', token);
            await storageAdapter.set('userId', userId);
            this.isLoggedIn = true;
            this.id = userId;
            this.token = token;
            apiAdapter.setToken(token);
        },

        async setUser() {
            const user = await this.getUserInfo();
            if (user) {
                this.user = user;
                const facilityId = user.roles[0].entityId;
                if (facilityId) {
                    this.facilityId = facilityId;
                }
            }
            await this.generateQrCode()
        },
        async register(input: RegisterPayload) {
            const { token, userId } = await apiAdapter.post(`${endpointUserService}/register`, input);
            if (token && userId) {
                await this.saveInStorage(token, userId);
            }
        },
        async logout() {
            await storageAdapter.clear();
        },
        async getUserInfo() {
           const r = await apiAdapter.get(`${endpointUserService}/${this.id}`);
           if (r) {
                return new User(r.email, r.firstName, r.lastName, r.userId, r.roles, r.token);
           } else {
                return null;
           }
        },
        async checkUserAuth() {
            // if store is set then user must be logged in
            if (this.isLoggedIn && this.user) {
                return true;
            }

            const authToken: string | null = await storageAdapter.get('token');
            const userId: string | null = await storageAdapter.get('userId');

            if (!authToken || !userId) {
                return false;
            }

            apiAdapter.setToken(authToken);
            try {
                await this.saveInStorage(authToken, userId);
                await this.setUser();
            } catch {
                throw new Error('Invalid token. Please log in again.');
            }

            return false;

        },

        setFacility(facility: IFacility) {
            this.facilityName = facility.facilityName;
            this.organizationId = facility.organizationId;
        },

        /**
         * @param facilityId 
         */
        async generateQrCode() {
            try {
                const facility: IFacility = await apiAdapter.get(`${endpointFacilityService}/${this.facilityId}`);
                this.setFacility(facility);
                const payload = JSON.stringify({ id: facility.facilityId, name: facility.facilityName });
                const dataUrl = await QRCode.toDataURL(payload, {
                    scale: 20,
                });
                this.qrCode = dataUrl;
            } catch (err) {
                console.error(err)
            }
        },

        setHandlerName(name: string) {
            this.handlerName = name;
        },

        clearHandlerName() {
            this.handlerName = null;
        }
    }
})