import { defineStore } from 'pinia';
import { apiAdapter } from '@/api/ApiAdapter';
import type { IHistoryEntry, IHistoryStore, UserInputRvmDeposit } from '@/types';
import { endpointMgmtAppService } from '@/api/Constants';
import { HistoryEntry } from '@/entities';
import { useUserStore } from './UserStore';
import { registerManualRvmReceipt } from '@/api/Deposits';





export const useHistoryStore = defineStore('history', {
    state: () => ({
        history: [],
    } as IHistoryStore),

    actions: {
        async getHistoryForFacility(facilityId: string) {
            try {
                const history: IHistoryEntry[] = await apiAdapter.get(`${endpointMgmtAppService}/history/facility/${facilityId}`);
                // Map into semantic type
                this.setHistory(history);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },

        setHistory(entries: IHistoryEntry[]) {
            if (Array.isArray(entries) && entries.length > 0) {
                this.history = entries.map(h => new HistoryEntry(h));
            }
        },

        patchHistory(entry: IHistoryEntry) {
            const historyEntry = new HistoryEntry(entry);
            this.history = [historyEntry, ...this.history];
         },


        async updateHistoryRecord(txId: string, ticketNumber: string) {
            try {
                const userStore = useUserStore();
                const { facilityId, handlerName } = userStore;
                const payload = {
                    facilityId: facilityId,
                    ticketNumber: ticketNumber,
                    handledBy: handlerName,
                }
                const updatedRecord: IHistoryEntry = await apiAdapter.post(`${endpointMgmtAppService}/history/${txId}/update`, payload);
                this.replaceHistoryRecord(updatedRecord);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },

        replaceHistoryRecord(entry: IHistoryEntry) {
            const historyEntry = new HistoryEntry(entry);
            const index = this.history.findIndex(h => h.id === historyEntry.id);
            if (index > -1) {
                this.history.splice(index, 1, historyEntry);
            }
        },


        async createRvmDepositRecord(userInput: UserInputRvmDeposit) {
            const userStore = useUserStore();
            const { facilityId, facilityName, handlerName } = userStore;
            const payload = {
                ...userInput,
                amount: Number(userInput.amount),
                facilityId: facilityId as string,
                facilityName: facilityName as string,
                handledBy: handlerName as string,
            }
            await registerManualRvmReceipt(payload);
        },
       
    }
});